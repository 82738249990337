import { computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { Session, SessionService, TScriptId, TSessionId, TShootId, TUpdateSessionRequest } from '/lib/api';
import { ISODateString } from '/src/types';

export class SessionStore extends BaseStore {
  currentSession?: Session;
  sessionList: Session[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentSession: observable,
      sessionList: observable,
      lastSessionDate: computed,
    });
  }

  get lastSessionDate(): string | undefined {
    return this.sessionList.toSorted((a, b) => ((a.date ?? '') > (b.date ?? '') ? -1 : 1))[0]?.date ?? '';
  }

  getSessionDates = (sessionId?: TSessionId): string[] => {
    return (this.sessionList
      .filter((se) => se.id !== sessionId)
      .toSorted((a, b) => ((a.date ?? '') > (b.date ?? '') ? -1 : 1))
      .map((se) => se.date ?? '') ?? []) as string[];
  };

  setCurrentSession(session?: Session) {
    runInAction(() => (this.currentSession = session));
  }

  setSessionList(sessionList: Session[]) {
    runInAction(() => (this.sessionList = sessionList));
  }

  listSessions = async (shootId: string | undefined) => {
    if (!shootId) return;
    try {
      const { sessions } = await SessionService.listSessions(shootId);
      this.setSessionList(sessions);
      return sessions;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSession = async (sessionId: TSessionId, relations = 'activities,session_estimate,script') => {
    try {
      const { session } = await SessionService.getSession(sessionId, relations);
      const a = await this.rootStore.sessionActivityStore.listSessionActivities(sessionId);
      if (session) {
        if (a) session.activities = a;
        return session;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  createSession = async (date: ISODateString, shootId: TShootId, scriptId?: TScriptId) => {
    if (!shootId || !date) return;
    try {
      const { session } = await SessionService.createSession({ date, shootId, scriptId });
      const newSession = await this.getSession(session?.id, 'activities,script');
      if (newSession) {
        runInAction(() => {
          this.sessionList.unshift(newSession);
        });
        return newSession;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSession = async (sessionId: TSessionId, update: TUpdateSessionRequest) => {
    const i = this.sessionList?.findIndex(({ id }) => id === sessionId);
    if (i === undefined || i === -1) return;
    try {
      const { session } = await SessionService.updateSession(sessionId, update);
      const updatedSession = await this.getSession(session.id, 'activities,script');
      if (updatedSession) {
        runInAction(() => {
          this.sessionList.splice(i, 1, updatedSession);
        });
        return updatedSession;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSession = async (sessionId: TSessionId) => {
    try {
      await SessionService.deleteSession(sessionId);
      if (sessionId === this.currentSession?.id) this.setCurrentSession(undefined);
      runInAction(() => {
        this.sessionList = this.sessionList?.filter((p) => p.id !== sessionId);
      });
    } catch (e) {
      this.handleError(e);
    }
  };
}
