import { Box, Heading, Spinner, Tab, Tabs, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import { EstimateHeader, SessionEstimateList } from '..';
import { useEstimateStore } from '/src/context';

export interface ICurrentEstimateProps {
  gridArea?: string;
}
export const CurrentEstimate = observer(({ gridArea }: ICurrentEstimateProps) => {
  const estimateStore = useEstimateStore();

  const hasEstimates = !!estimateStore.estimateList.length;

  return (
    <Box
      round="medium"
      background="white"
      border={{ color: '#d9d9e3' }}
      justify="start"
      alignContent="start"
      gridArea={gridArea}
      direction="column"
      flex="grow"
    >
      {estimateStore.isLoading && <Spinner />}
      {!estimateStore.isLoading && !hasEstimates && (
        <Box fill align="center" justify="center">
          <Text>Create or choose an Estimate to begin.</Text>
        </Box>
      )}

      {!estimateStore.isLoading && hasEstimates && (
        <Box>
          <EstimateHeader />
          <Tabs>
            <Tab title="Sessions">
              <SessionEstimateList />
            </Tab>
            <Tab title="Usage">
              <Box direction="column" align="center">
                <Heading level="3" margin="medium">
                  Usage
                </Heading>
              </Box>
            </Tab>
          </Tabs>
        </Box>
      )}
    </Box>
  );
});
