import dayjs from 'dayjs';
import { Box, Button, DateInput, Keyboard, Layer, Text, TextInput } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useEstimateStore } from '/src/context';
import { DateString, FormErrors } from '/src/types';

export interface ICreateSessionEstimateModalProps {
  addSessionEstimate: (title: string, date: DateString) => Promise<void>;
  setShowModal: (isVisible: boolean) => void;
}

export const CreateSessionEstimateModal = observer(
  ({ addSessionEstimate, setShowModal }: ICreateSessionEstimateModalProps) => {
    /** Context **/
    const estimateStore = useEstimateStore();

    /** State **/
    const [date, setDate] = useState(
      estimateStore.lastSessionEstimateDate
        ? dayjs(estimateStore.lastSessionEstimateDate).add(1, 'day').toISOString()
        : ''
    );
    const [title, setTitle] = useState(
      `Session #${(estimateStore.currentEstimate?.session_estimates.length ?? 0) + 1}`
    );

    const [formErrors, setFormErrors] = useState<FormErrors>({});

    /** Methods **/
    const createSessionEstimate = () => {
      if (estimateStore.getSessionEstimateDates().find((d) => dayjs(d).isSame(date))) {
        setFormErrors({ ...formErrors, date: 'A Session Estimate already exists for this date.' });
        return;
      }

      addSessionEstimate(title, date);
      setShowModal(false);
    };

    /** Render **/
    return (
      <Layer
        onEsc={() => setShowModal(false)}
        onClickOutside={() => setShowModal(false)}
        position="center"
        modal={true}
        margin="large"
      >
        <Box
          direction="column"
          align="start"
          pad="small"
          justify="around"
          width="medium"
          height="medium"
          background="light-1"
          round="small"
        >
          <Box direction="row" pad="small" gap="small" align="center" fill="horizontal">
            <Box width="20%">
              <Text>Title:</Text>
            </Box>
            <Box fill="horizontal">
              <Keyboard onEnter={createSessionEstimate}>
                <TextInput value={title} onChange={(e) => setTitle(e.target.value)} />
              </Keyboard>
            </Box>
          </Box>
          <Box>
            <Box direction="row" pad="small" gap="small" align="center" fill="horizontal">
              <Box width="20%">
                <Text>Date:</Text>
              </Box>
              <Box fill="horizontal" gap="xsmall">
                <Keyboard onEnter={createSessionEstimate}>
                  <DateInput
                    format="mm/dd/yyyy"
                    value={date}
                    calendarProps={{ disabled: estimateStore.getSessionEstimateDates() }}
                    onChange={({ value }) => setDate(typeof value === 'object' ? value[0] : value)}
                  />
                </Keyboard>
                {formErrors.date && (
                  <Box fill="horizontal" align="end">
                    <Text size="xsmall" color="red">
                      {formErrors.date}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Button
            pad="small"
            margin="small"
            hoverIndicator
            onClick={() => createSessionEstimate()}
            label="Add Session Estimate"
          />
        </Box>
      </Layer>
    );
  }
);
