import { FloatString, Nullable } from './commonTypes';
import { SessionEstimateItemUnitType } from '/lib/api';

export type TSessionEstimateForm = {
  title: string;
  date: Nullable<Date>;
};

export type TSessionEstimateItemForm = {
  title: string;
  units: FloatString;
  unitType: SessionEstimateItemUnitType;
  rate: FloatString;
  taxesMultiplier: FloatString;
  handlingMultiplier: FloatString;
  pAndHMultiplier: FloatString;
  [key: string]: string | FloatString | SessionEstimateItemUnitType;
};
