import dayjs from 'dayjs';
import { Box, Button, DateInput, Keyboard, Layer, Select, Text } from 'grommet';
import { Book } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { TScriptId } from '/lib/api';
import { useProjectStore, useSessionStore } from '/src/context';
import { DateString, FormErrors } from '/src/types';

export interface ICreateSessionModalProps {
  addSession: (date: DateString, scriptId?: TScriptId) => Promise<void>;
  setShowModal: (isVisible: boolean) => void;
}

export const CreateSessionModal = observer(({ addSession, setShowModal }: ICreateSessionModalProps) => {
  /** Context **/
  const sessionStore = useSessionStore();
  const projectStore = useProjectStore();

  /** State **/
  const [date, setDate] = useState(
    sessionStore.lastSessionDate ? dayjs(sessionStore.lastSessionDate).add(1, 'day').toISOString() : ''
  );
  const [scriptId, setScriptId] = useState<TScriptId>('');

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  /** Computed **/
  const scripts = useMemo(() => {
    return projectStore.currentProject?.scripts.map((s) => ({ name: s.name, id: s.id })) ?? [];
  }, [projectStore.currentProject?.scripts]);

  /** Methods **/
  const createSession = () => {
    if (sessionStore.getSessionDates().find((d) => dayjs(d).isSame(date))) {
      setFormErrors({ ...formErrors, date: 'A Session already exists for this date.' });
      return;
    }

    addSession(date, scriptId);
    setShowModal(false);
  };

  /** Render **/
  return (
    <Layer
      onEsc={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
      position="center"
      modal={true}
      margin="large"
    >
      <Box
        direction="column"
        align="start"
        pad="small"
        justify="around"
        width="medium"
        height="medium"
        background="light-1"
        round="small"
      >
        <Box>
          <Box direction="row" pad="small" gap="small" align="center" fill="horizontal">
            <Box width="20%">
              <Text>Date:</Text>
            </Box>
            <Box fill="horizontal" gap="xsmall">
              <Keyboard onEnter={createSession}>
                <DateInput
                  format="mm/dd/yyyy"
                  value={date}
                  calendarProps={{ disabled: sessionStore.getSessionDates() }}
                  onChange={({ value }) => setDate(typeof value === 'object' ? value[0] : value)}
                />
              </Keyboard>
              {formErrors.date && (
                <Box fill="horizontal" align="end">
                  <Text size="xsmall" color="red">
                    {formErrors.date}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box direction="row" gap="small" pad="small" align="center" fill="horizontal">
          <Box width="20%">
            <Text>Shoot:</Text>
          </Box>
          <Box fill="horizontal">
            <Select
              id="select-script"
              name="script"
              placeholder="Choose..."
              options={scripts}
              labelKey="name"
              valueKey="id"
              onChange={({ value }) => setScriptId(value.id)}
              icon={<Book />}
            />
          </Box>
        </Box>
        <Button pad="small" margin="small" hoverIndicator onClick={() => createSession()} label="Add Session" />
      </Box>
    </Layer>
  );
});
