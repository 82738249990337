/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Keyboard, Text, TextInput } from 'grommet';
import { WidthType } from 'grommet/utils';
import { useEffect, useState } from 'react';
import { TalonTip } from '.';
import { useDebounce } from '../../utils/useDebounce';

export interface IEditableTextProps {
  val: any;
  onChange: (e: any) => void;
  onBlur?: () => void;
  onSubmit?: () => void;
  required?: boolean;
  width?: WidthType;
  textSize?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  textMargin?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  inputType?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url';
  shortenTo?: number;
  isCurrency?: boolean;
  isPercent?: boolean;
}

export const EditableText = ({
  val,
  onChange,
  onBlur,
  onSubmit,
  required,
  width,
  textSize,
  textMargin,
  inputType,
  shortenTo,
  isCurrency,
  isPercent,
}: IEditableTextProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<any>(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  const debouncedRequest = useDebounce(() => {
    onChange(value);
  });

  const doChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);

    debouncedRequest();
  };

  const doBlur = () => {
    setEdit(false);
    if (onBlur) onBlur();
  };

  const doKeyDown = () => {
    setEdit(false);
    if (onSubmit) onSubmit();
  };

  const takeFocus = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(true);
  };

  return edit ? (
    <Box width={width} margin={textMargin} alignSelf="start" gap="xxsmall" direction="row" align="center">
      {isCurrency && <Text>$</Text>}
      <Keyboard onEnter={doKeyDown}>
        <TextInput
          value={value}
          onChange={doChange}
          onBlur={doBlur}
          size={textSize}
          plain="full"
          type={inputType}
          style={{ backgroundColor: 'accent-3' }}
          onClick={(e) => takeFocus(e)}
          required={required}
        />
      </Keyboard>
      {isPercent && <Text>%</Text>}
    </Box>
  ) : (
    <Box width={width} border={{ side: 'bottom', color: 'accent-3' }} margin={textMargin} alignSelf="start">
      {shortenTo ? (
        <TalonTip content={<Text>{value}</Text>}>
          <Text style={{ cursor: 'text' }} onClick={(e) => takeFocus(e)} size={textSize}>
            {value.length > shortenTo ? `${value.slice(0, shortenTo - 1)}...` : value}
          </Text>
        </TalonTip>
      ) : (
        <Box direction="row">
          {isCurrency && <Text>$</Text>}
          <Text style={{ cursor: 'text' }} onClick={(e) => takeFocus(e)} size={textSize}>
            {value}
          </Text>
          {isPercent && <Text>%</Text>}
        </Box>
      )}
    </Box>
  );
};
