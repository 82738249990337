import { Box, Button, DateInput, Keyboard, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { DeleteModal } from '../../DeleteModal';
import { EditableTextV2 } from '../../EditableTextV2';
import { SessionEstimate } from '/lib/api';
import { useEstimateStore } from '/src/context';
import { USD } from '/src/utils/currency';

export interface ISessionEstimateHeaderProps {
  sessionEstimate: SessionEstimate;
  setSessionEstimate: (sessionEstimate: SessionEstimate) => void;
}

export const SessionEstimateHeader = observer(
  ({ sessionEstimate, setSessionEstimate }: ISessionEstimateHeaderProps) => {
    /** Context **/
    const estimateStore = useEstimateStore();

    /** State **/
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [title, setTitle] = useState(sessionEstimate.title);
    const [date, setDate] = useState(sessionEstimate.date ?? '');

    /** Methods **/
    const deleteSessionEstimate = async () => {
      await estimateStore.deleteSessionEstimate(sessionEstimate.id);
    };

    /** Render **/
    return (
      <Box>
        <Box
          direction="row"
          gap="small"
          background="brand"
          style={{
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          }}
          pad="medium"
          align="center"
        >
          <Box fill="horizontal">
            {estimateStore.canEditCurrent ? (
              <EditableTextV2
                val={title}
                onChange={setTitle}
                onSubmit={() => setSessionEstimate({ ...sessionEstimate, title })}
                onBlur={() => setSessionEstimate({ ...sessionEstimate, title })}
              />
            ) : (
              <Text>{title}</Text>
            )}
          </Box>
          <Box direction="row" gap="xsmall" align="end" fill="horizontal">
            <Text size="small">DATE:</Text>
            {estimateStore.canEditCurrent ? (
              <Keyboard onEnter={() => setSessionEstimate({ ...sessionEstimate, date })}>
                <DateInput
                  format="mm/dd/yyyy"
                  value={date}
                  calendarProps={{ disabled: estimateStore.getSessionEstimateDates(sessionEstimate.id) }}
                  onChange={({ value }) => setDate(typeof value === 'object' ? value[0] : value)}
                />
              </Keyboard>
            ) : (
              <Text>{date}</Text>
            )}
          </Box>
          <Box direction="row" gap="xsmall" align="end" fill="horizontal">
            <Text size="small">SUBTOTAL:</Text>
            <Text weight="bold">{USD(sessionEstimate.subtotal)}</Text>
          </Box>
          <Box direction="row" gap="xsmall" align="end" fill="horizontal">
            <Text size="small">SUBTOTAL + FRINGE:</Text>
            <Text weight="bold">{USD(sessionEstimate.subtotal_plus_fringe)}</Text>
          </Box>
          <Box width="10%" align="center">
            {estimateStore.canEditCurrent && (
              <Button
                plain
                icon={<Trash size="16px" color="red" />}
                tip={`Delete ${sessionEstimate?.title}`}
                onClick={() => setShowDeleteModal(true)}
              />
            )}
          </Box>
        </Box>
        {estimateStore.canEditCurrent && showDeleteModal && sessionEstimate && (
          <DeleteModal
            name={sessionEstimate.title}
            onDelete={deleteSessionEstimate}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}
      </Box>
    );
  }
);
