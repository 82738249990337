import { Box, Text } from 'grommet';

export const TitleRow = () => {
  return (
    <Box
      direction="row"
      gap="medium"
      pad={{ vertical: 'small', horizontal: 'medium' }}
      border={{ side: 'bottom', size: 'xsmall' }}
      background="white"
    >
      <Box fill="horizontal">
        <Text size="small">TITLE</Text>
      </Box>
      <Box width="75%">
        <Text size="small">UNITS</Text>
      </Box>
      <Box width="55%">
        <Text size="small">RATE</Text>
      </Box>
      <Box width="60%">
        <Text size="small" weight={600}>
          SUBTOTAL
        </Text>
      </Box>
      <Box width="40%">
        <Text size="small">P & H</Text>
      </Box>
      <Box width="40%">
        <Text size="small">HANDLING</Text>
      </Box>
      <Box width="40%">
        <Text size="small">TAXES</Text>
      </Box>
      <Box width="70%">
        <Text size="small" weight={600}>
          SUBTOTAL + FRINGE
        </Text>
      </Box>
      <Box width="10%" />
    </Box>
  );
};
