import { Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { CreateSessionEstimateModal } from '../CreateSessionEstimateModal';
import { SessionEstimatePanel } from './SessionEstimatePanel';
import { Estimate } from '/lib/api';
import { useEstimateStore } from '/src/context';
import { DateString } from '/src/types';

export interface ISessionEstimateListProps {
  estimate: Estimate;
}

export const SessionEstimateList = observer(() => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** State **/
  const [showAddModal, setShowAddModal] = useState(false);

  /** Computed **/
  const sessionEstimates = useMemo(
    () => estimateStore.currentEstimate?.session_estimates?.toSorted((a, b) => (a.created_at > b.created_at ? 1 : -1)),
    [estimateStore.currentEstimate?.session_estimates]
  );

  /** Methods **/
  const addSessionEstimate = async (title?: string, date?: DateString) => {
    await estimateStore.createSessionEstimate(title, date);
  };

  /** Render **/
  return (
    <Box gap="small" margin={{ horizontal: 'small', bottom: 'medium' }} pad="small">
      {estimateStore.canEditCurrent && (
        <Box alignSelf="end" pad={{ vertical: 'xsmall' }} margin={{ left: 'medium' }}>
          <Button
            secondary
            label={
              <Text size="small" weight="bold">
                ADD SESSION ESTIMATE
              </Text>
            }
            icon={<Add size="16px" />}
            tip="Add estimate item"
            onClick={() => setShowAddModal(true)}
          />
        </Box>
      )}
      <Box align="center">
        {sessionEstimates && !!sessionEstimates?.length ? (
          sessionEstimates.map((sessionEstimate) => (
            <SessionEstimatePanel key={sessionEstimate.id} sessionEstimate={sessionEstimate} />
          ))
        ) : (
          <Box align="center">
            <Text>No Session Estimates found.</Text>
          </Box>
        )}
      </Box>
      {estimateStore.canEditCurrent && showAddModal && (
        <CreateSessionEstimateModal addSessionEstimate={addSessionEstimate} setShowModal={setShowAddModal} />
      )}
    </Box>
  );
});
