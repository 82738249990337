import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import {
  Document,
  DocumentService,
  Location,
  LocationService,
  Project,
  ProjectService,
  ScriptService,
  SecretService,
  TCreateLocationRequest,
  TCreateProjectRequest,
  TUpdateLocationRequest,
} from '/lib/api';
import { StorageProperties } from '/src/types/storageTypes';
import Storage from '/src/utils/storage';

export class ProjectStore extends BaseStore {
  currentProject?: Project = {} as Project;
  projectList?: Project[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentProject: observable,
      projectList: observable,
      setCurrentProjectId: action,
      clientList: computed,
    });
  }

  /**     PROJECTS      */
  fetchProjectList = async () => {
    try {
      // TODO implement pagination
      const { projects } = await this.q(
        ProjectService.listProjects(undefined, undefined, undefined, 'documents,shoots,estimates,scripts')
      );
      runInAction(() => {
        this.projectList = projects;
        if (Storage.get(StorageProperties.CurrentProjectId)) {
          this.setCurrentProjectId(Storage.get(StorageProperties.CurrentProjectId));
        }
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  createProject = async ({ name, groupId, client, product, jobCode }: TCreateProjectRequest) => {
    try {
      const { project } = await this.q(ProjectService.createProject({ name, groupId, client, product, jobCode }));
      runInAction(() => {
        this.projectList?.push(project);
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  setCurrentProjectId = (projectId: string) => {
    this.currentProject = this.projectList?.find((project) => project.id === projectId);
    Storage.set(StorageProperties.CurrentProjectId, projectId);
  };

  deleteProject = async (projectId: string) => {
    try {
      await this.q(ProjectService.deleteProject(projectId));
      runInAction(() => {
        this.projectList = this.projectList?.filter((p) => p.id !== projectId);
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  updateProject = async (projectId: string, updatedProject: Project) => {
    try {
      const { project } = await this.q(ProjectService.updateProject(projectId, updatedProject));
      if (!project) throw new Error('No project returned from update');
      // retrieve documents and shoots
      const { project: fullProject } = await this.q(ProjectService.getProject(projectId, 'documents,shoots,estimates'));
      if (!fullProject) throw new Error('Could not retrieve updated project details');
      runInAction(() => {
        this.projectList = this.projectList?.map((p) => (p.id === projectId ? fullProject : p));
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  /**         SCRIPTS       */

  getScriptsByProject = async (projectId: string) => {
    try {
      const { scripts } = await this.q(ScriptService.listScripts(projectId));
      return scripts;
    } catch (e) {
      this.handleError(e);
    }
  };

  getScriptByDocId = async (documentId: string) => {
    try {
      const { script } = await this.q(ScriptService.getScriptByDocId(documentId));
      return script;
    } catch (e) {
      this.handleError(e);
    }
  };

  /**         DOCUMENTS     */
  createDocument = async (file: File, documentType: string) => {
    if (!this.currentProject) {
      throw new Error('No current project');
    }

    try {
      const { document } = await this.q(
        DocumentService.createDocument({
          file,
          name: file.name,
          type: documentType,
          project_id: this.currentProject.id,
        })
      );
      if (document) {
        runInAction(() => {
          this.currentProject?.documents?.push(document);
        });
      }
      return document;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSignedDownloadLink = async (documentId: string) => {
    try {
      const url = await this.q(DocumentService.getSignedUrl(documentId));
      return url;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateDocument = async (documentId: string, updatedDocument: Document) => {
    const request = {
      ...updatedDocument,
      project_id: this.currentProject?.id,
    };
    try {
      const { document } = await this.q(DocumentService.updateDocument(documentId, request));
      return document;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteDocument = async (documentId: string) => {
    try {
      await this.q(DocumentService.deleteDocument(documentId));
      runInAction(() => {
        if (this.currentProject) {
          this.currentProject.documents = this.currentProject?.documents?.filter((d) => d.id !== documentId);
        }
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  get clientList() {
    if (!this.projectList) return [];
    return this.projectList.reduce((list: string[], p: Project) => {
      if (!p?.client) return list;
      return list.includes(p.client) ? list : list.concat(p.client);
    }, []);
  }

  getMapApiKey = async () => {
    try {
      const { key } = await this.q(SecretService.getSecretKey({ key: 'mapApiKey' }));
      return key;
    } catch (e) {
      this.handleError(e);
    }
  };

  /**        LOCATIONS      */

  getLocationsByProjectId = async (projectId: string) => {
    try {
      const { locations } = await this.q(LocationService.listLocations(projectId));
      return locations;
    } catch (e) {
      this.handleError(e);
    }
  };

  createLocation = async (location: TCreateLocationRequest) => {
    try {
      const { location: createdLocation } = await this.q(LocationService.createLocation(location));
      return createdLocation;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteLocation = async (locationId: string) => {
    try {
      await this.q(LocationService.deleteLocation(locationId));
    } catch (e) {
      this.handleError(e);
    }
  };

  updateLocation = async (updatedLocation: Location) => {
    const request: TUpdateLocationRequest = {
      ...updatedLocation,
      sessionActivityIds: updatedLocation.session_activities?.map((sa) => sa.id),
    };
    try {
      const { location } = await this.q(LocationService.updateLocation(updatedLocation.id, request));
      return location;
    } catch (e) {
      this.handleError(e);
    }
  };
}
