import { SessionActivityType } from '/lib/api';

export const TravelActivityTypes = [SessionActivityType.TRAVEL_TO_LOCATION, SessionActivityType.TRAVEL_FROM_LOCATION];

// const MealActivityTypes = [
//   SessionActivityType.MEAL_BREAKFAST,
//   SessionActivityType.MEAL_LUNCH,
//   SessionActivityType.MEAL_DINNER,
// ];

export const WorkActivityTypes = [
  SessionActivityType.WORK_TIME,
  SessionActivityType.HAIR_AND_MAKEUP,
  SessionActivityType.FITTING,
  SessionActivityType.REHEARSAL,
  SessionActivityType.RECORDING_SESSION,
  SessionActivityType.VOICE_OVER,
];

export const AuditionActivityTypes = [
  SessionActivityType.AUDITION,
  SessionActivityType.AUDITION_SCRATCH_TRACK,
  SessionActivityType.CREATIVE_AUDITION,
];
