import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { Shoot, ShootService, TCreateShootRequest } from '/lib/api';

export class ShootStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  listShoots = async (projectId: string) => {
    if (!projectId) return;
    try {
      const { shoots } = await ShootService.listShoots(projectId, undefined, undefined, undefined, 'estimate');
      return shoots;
    } catch (e) {
      this.handleError(e);
    }
  };

  createShoot = async (projectId: string, shootName?: string) => {
    if (!projectId) return;
    const createShootRequest: TCreateShootRequest = {
      name: shootName || 'New Shoot',
      projectId: projectId,
    };
    try {
      const { shoot } = await ShootService.createShoot(createShootRequest);
      return shoot;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateShoot = async (updatedShoot: Shoot) => {
    try {
      const { shoot } = await ShootService.updateShoot(updatedShoot.id, updatedShoot);
      if (!shoot || !this.rootStore.estimateStore.currentEstimate?.id || !shoot.project_id) return;
      if (this.rootStore.estimateStore.currentEstimate.shoot?.id === shoot.id) {
        this.rootStore.estimateStore.setCurrentEstimate({ ...this.rootStore.estimateStore.currentEstimate, shoot });
      }
      return shoot;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteShoot = async (shootId: string) => {
    try {
      await ShootService.deleteShoot(shootId);
    } catch (e) {
      this.handleError(e);
    }
  };
}
