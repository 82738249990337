import { Box, Button, Layer, Text } from 'grommet';

export interface IDeleteModalProps {
  name: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteModal = ({ name, onDelete, onCancel }: IDeleteModalProps) => {
  return (
    <Layer onEsc={onCancel} onClickOutside={onCancel} position="center" margin="large" modal>
      <Box pad="medium" gap="medium">
        <Text>Are you sure you want to delete {name}?</Text>
        <Box direction="row" align="center" justify="end" gap="small">
          <Button label="Cancel" onClick={onCancel} />
          <Button label="Delete" onClick={onDelete} primary />
        </Box>
      </Box>
    </Layer>
  );
};
