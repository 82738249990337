/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Keyboard, Text, TextInput } from 'grommet';
import { WidthType } from 'grommet/utils';
import { useState } from 'react';
import { TalonTip } from '.';

export interface IEditableTextV2Props {
  val: string;
  onChange: (val: any) => void;
  onBlur?: (val?: any) => void;
  onSubmit?: (val?: any) => void;
  id?: string;
  name?: string;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  width?: WidthType;
  textSize?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  textMargin?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  inputType?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url';
  shortenTo?: number;
  isCurrency?: boolean;
  isPercent?: boolean;
}

export const EditableTextV2 = ({
  val,
  onChange,
  onBlur,
  onSubmit,
  id,
  name,
  minValue,
  maxValue,
  minLength,
  maxLength,
  required,
  width,
  textSize,
  textMargin,
  inputType,
  shortenTo,
  isCurrency,
  isPercent,
}: IEditableTextV2Props) => {
  const [edit, setEdit] = useState<boolean>(false);

  const doChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const value = typeof e.target.value === 'number' ? (e.target.value as number).toString() : e.target.value;
      if (minLength && value.length < minLength) return;
      if (maxLength && value.length > maxLength) return;
    }
    onChange && onChange(e.target.value);
  };

  const doSubmit = () => {
    setEdit(false);
    onSubmit && onSubmit();
  };

  const doBlur = () => {
    setEdit(false);
    onBlur && onBlur();
  };

  const takeFocus = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(true);
  };

  return edit ? (
    <Box width={width} margin={textMargin} alignSelf="start" gap="xxsmall" direction="row" align="center">
      {isCurrency && <Text>$</Text>}
      <Box border={{ side: 'bottom', color: 'accent-3' }} onBlur={doBlur}>
        <Keyboard onEnter={doSubmit}>
          <TextInput
            id={id}
            name={name || id}
            value={val}
            autoFocus={edit}
            onChange={doChange}
            onBlur={doBlur}
            type={inputType}
            required={required}
            min={minValue}
            max={maxValue}
            minLength={minLength}
            maxLength={maxLength}
            plain="full"
            size={textSize}
          />
        </Keyboard>
      </Box>
      {isPercent && <Text>%</Text>}
    </Box>
  ) : (
    <Box
      width={width}
      margin={textMargin}
      border={{ side: 'bottom', color: 'accent-3' }}
      alignSelf="start"
      onClick={takeFocus}
    >
      {shortenTo ? (
        <TalonTip content={<Text>{val}</Text>}>
          <Text style={{ cursor: 'text' }}>{val.length > shortenTo ? `${val.slice(0, shortenTo - 1)}...` : val}</Text>
        </TalonTip>
      ) : (
        <Box direction="row">
          {isCurrency && <Text>$</Text>}
          <Text style={{ cursor: 'text' }}>{val}</Text>
          {isPercent && <Text>%</Text>}
        </Box>
      )}
    </Box>
  );
};
