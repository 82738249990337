export type Nullable<T> = T | null;

// Numbers
export type FloatString = string;
export type IntString = string;

// Time
export type ISODateString = string;
export type DateString = string; // YYYY-MM-DD
export type TimeString = string; // h:mm A

export type Hours = IntString[];
export type Minutes = IntString[];
export type AmPm = ['AM', 'PM'];

export enum DateTimeType {
  StartTime = 'start_time',
  EndTime = 'end_time',
}

export type DateTimeObject = { date: DateString; time: TimeString };

// Forms
export type FormErrors = Record<string, string>;

export type SelectOption<T> = {
  label: string;
  value: T;
};

export type SelectOptions<T> = SelectOption<T>[];
