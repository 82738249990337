import dayjs from 'dayjs';
import { Box, Button, Calendar, DropButton, Text } from 'grommet';
import { Schedule } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ErrorText, TalonTip, TimeInput } from '.';
import { Session, SessionActivity } from '/lib/api';
import { DateString, DateTimeType, ISODateString, TimeString } from '/src/types';
import { isSameOrAfter, isSameOrBefore, parseIsoDateString } from '/src/utils/time';

export interface IDateTimeDropButtonV2Props {
  dateTime: ISODateString;
  onChange: (dateTime: ISODateString) => void;
  initialDate: DateString;
  session: Session;
  activity?: SessionActivity;
  bounds?: string[];
  disabled?: string[][];
  disabledTimes?: TimeString[];
  type?: DateTimeType;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchMessage?: string;
}

export const DateTimeDropButtonV2 = observer(
  ({ dateTime, onChange, initialDate, session, activity, bounds, type, placeholder }: IDateTimeDropButtonV2Props) => {
    /** State **/
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState<DateString>('');
    const [time, setTime] = useState<TimeString>('');
    const [dateLabel, setDateLabel] = useState('');
    const [timeLabel, setTimeLabel] = useState(placeholder || 'Select date & time');
    const [disabledDates, setDisabledDates] = useState<DateString[][]>([]);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});

    /** Methods **/

    const setDateValue = (date: DateString) => {
      setDate(date);
      formErrors.date = '';
    };

    const setTimeValue = (time: TimeString) => {
      setTime(time);
      formErrors.time = '';
    };

    const getDisabledDates = (date: DateString, session: Session) => {
      if (!date || !session) return [];

      const d = dayjs(date);
      const firstDayOfMonth = d.set('date', 1);
      const dayBefore = dayjs(session.date).subtract(1, 'day');

      const dates = dayBefore.isSame(session.date, 'month')
        ? [[firstDayOfMonth.toISOString(), dayBefore.toISOString()]]
        : [];
      return dates;
    };

    const isDateTimeValid = (isoDateTime: ISODateString): boolean => {
      if (!activity) return false;

      if (type === DateTimeType.StartTime) {
        if (isSameOrAfter(isoDateTime, activity.end_time)) {
          setFormErrors({
            ...formErrors,
            time: 'Start time must be before end time.',
          });
          return false;
        }
      } else if (type === DateTimeType.EndTime) {
        if (isSameOrBefore(isoDateTime, activity.start_time)) {
          setFormErrors({
            ...formErrors,
            time: 'End time must be after start time.',
          });
          return false;
        }
      }

      return true;
    };

    const resetDateTime = () => {
      const { date: initialDate, time: initialTime } = parseIsoDateString(dateTime);
      setDate(initialDate);
      setTime(initialTime);
    };

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = (nextDate: DateString, nextTime: TimeString, validateAndSave = true) => {
      if (validateAndSave) {
        const isoDateTime = new Date(`${nextDate} ${nextTime}`).toISOString();
        if (isDateTimeValid(isoDateTime)) onChange(isoDateTime);
        else return;
      } else {
        resetDateTime();
      }

      setOpen(false);
      setTimeout(() => setOpen(false), 1);
      setFormErrors({});
    };

    /** Effects **/
    useEffect(() => {
      if (dateTime) resetDateTime();
    }, [dateTime]);

    useEffect(() => {
      setTimeLabel(time || placeholder || 'Select date & time');
      if (date) setDateLabel(date !== initialDate ? dayjs(date).format('MM/DD/YYYY') : '');
    }, [time, placeholder, date, initialDate]);

    useEffect(() => {
      if (date && session) {
        const dates = getDisabledDates(date, session);
        setDisabledDates(dates);
      }
    }, [date, session]);

    /** Render **/
    return (
      <Box align="center" pad="small" flex="grow">
        <DropButton
          open={open}
          onClose={() => onClose(date, time)}
          onOpen={onOpen}
          dropProps={{
            onClickOutside: () => onClose(date, time, false),
            onEsc: () => onClose(date, time, false),
          }}
          dropContent={
            <Box align="center">
              <Calendar
                fill
                animate={false}
                date={date}
                onSelect={(d) => setDateValue(!Array.isArray(d) ? d : '')}
                showAdjacentDays={false}
                locale="en-US"
                bounds={bounds}
                disabled={disabledDates}
              />
              <Box flex={false} pad="small" gap="medium" fill="horizontal">
                <Box gap="xsmall">
                  <Box direction="row" border={{ side: 'bottom' }}>
                    <TimeInput time={time} setTime={setTimeValue} boxProps={{ flex: 'grow' }} />
                  </Box>
                  {formErrors.time && <ErrorText>{formErrors.time}</ErrorText>}
                </Box>
                <Box>
                  <Button label="Done" onClick={() => onClose(date, time)} />
                </Box>
              </Box>
            </Box>
          }
        >
          <Box direction="row" gap="medium" align="center" pad={{ vertical: 'small' }}>
            <Box align="center" gap="xsmall">
              {dateLabel && <Text color={date ? undefined : 'dark-2'}>{dateLabel}</Text>}
              <Text color={date ? undefined : 'dark-2'}>{timeLabel}</Text>
            </Box>
            <TalonTip content={<Text>{placeholder}</Text>}>
              <Schedule />
            </TalonTip>
          </Box>
        </DropButton>
      </Box>
    );
  }
);
