/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Select, SelectProps, Text } from 'grommet';
import { WidthType } from 'grommet/utils';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { TalonTip } from '.';
import { SelectOption } from '/src/types';

export interface ISelectableTextProps {
  val: any;
  options: SelectProps['options'];
  onChange: (value: string) => void;
  onBlur?: () => void;
  onSubmit?: () => void;
  id?: string;
  name?: string;
  required?: boolean;
  width?: WidthType;
  textSize?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  textMargin?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  shortenTo?: number;
}

export const SelectableText = ({
  val,
  options,
  onChange,
  onBlur,
  onSubmit,
  id,
  name,
  required,
  width,
  textSize,
  textMargin,
  shortenTo,
}: ISelectableTextProps) => {
  const [edit, setEdit] = useState<boolean>(false);

  const doChange = (selectedOption: SelectOption<any>) => {
    setEdit(false);
    onChange && onChange(selectedOption.value);
  };

  const doSubmit = () => {
    setEdit(false);
    onSubmit && onSubmit();
  };

  const doBlur = () => {
    setEdit(false);
    onBlur && onBlur();
  };

  const takeFocus = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!edit) setEdit(true);
  };

  return edit ? (
    <Box width={width} margin={textMargin} alignSelf="start" gap="xxsmall" direction="row" align="center">
      <Box border={{ side: 'bottom', color: 'accent-3' }}>
        <Select
          id={id}
          name={name || id}
          value={val}
          onChange={({ value }) => doChange(value)}
          open
          onClose={doBlur}
          onSubmit={doSubmit}
          labelKey="label"
          valueKey="value"
          options={options}
          required={required}
          size={textSize}
          plain
        />
      </Box>
    </Box>
  ) : (
    <Box
      width={width}
      margin={textMargin}
      border={{ side: 'bottom', color: 'accent-3' }}
      alignSelf="start"
      onClick={takeFocus}
    >
      {shortenTo ? (
        <TalonTip content={<Text>{val}</Text>}>
          <Text style={{ cursor: 'text' }}>
            {capitalize(val.length > shortenTo ? `${val.slice(0, shortenTo - 1)}...` : val)}
          </Text>
        </TalonTip>
      ) : (
        <Box direction="row">
          <Text style={{ cursor: 'text' }}>{capitalize(val)}</Text>
        </Box>
      )}
    </Box>
  );
};
