import { Box, Text } from 'grommet';
import { PropsWithChildren } from 'react';

export const ErrorText = ({ children }: PropsWithChildren) => {
  return (
    <Box direction="row">
      <Text color="red" size="small">
        {children}
      </Text>
    </Box>
  );
};
