import { useRef, useState, useEffect } from 'react';
import { Message } from '/src/types';
import { Box, Card, Collapsible } from 'grommet';
import { Chat, Close } from 'grommet-icons';

import { THistoryItem, ChatService } from '/lib/api';
import { observer } from 'mobx-react-lite';

import { toast } from 'react-toastify';
import { MessageList, UserInput } from '../../../components/ui';
import { useProjectStore, useUserStore } from '../../../context';

export const ChatWidget = observer(() => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [messageState, setMessageState] = useState<{
    conversationId?: string;
    messages: Message[];
    pending?: string;
    history: THistoryItem[];
  }>({
    messages: [
      {
        message: 'Hi, what would you like to know about working with union talent?',
        type: 'apiMessage',
      },
    ],
    history: [],
  });
  const [showChat, setShowChat] = useState<boolean>(false);
  const projectStore = useProjectStore();
  const userStore = useUserStore();
  const messageListRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const resetChat = async () => {
      if (projectStore.currentProject?.id) {
        setMessageState({
          // overwrite prior state
          messages: [
            {
              message: 'Hi, what would you like to know about working with union talent?',
              type: 'apiMessage',
            },
          ],
          history: [],
        });
      }
    };
    resetChat();
  }, [projectStore.currentProject?.id]);

  useEffect(() => {
    if (messageListRef.current) {
      //scroll to bottom
      messageListRef.current?.scrollTo(0, messageListRef.current.scrollHeight);
    }
  }, [messageState.messages]);

  //handle form submission
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { history, conversationId } = messageState;
    if (!projectStore.currentProject?.id) {
      toast.error('Please select a project first.');
      return;
    }
    const question = query.trim();

    setMessageState((state) => ({
      ...state,
      messages: [
        ...state.messages,
        {
          type: 'userMessage',
          message: question,
        },
      ],
    }));

    setLoading(true);
    setQuery('');

    try {
      const data = await ChatService.chat({
        question,
        history,
        conversationId,
        projectId: projectStore.currentProject?.id,
      });

      setMessageState((state) => ({
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'apiMessage',
            message: data.text,
            sourceDocs: data.sourceDocuments,
          },
        ],
        history: [...state.history, { question, answer: data.text }],
        conversationId: data.conversationId,
      }));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('An error occurred while fetching the data. Please try again.');
      console.log('error', error);
    }
  }

  //prevent empty submissions
  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && query) {
      handleSubmit(e);
    } else if (e.key == 'Enter') {
      e.preventDefault();
    }
  };

  if (!userStore.user?.id) {
    return null;
  }

  return (
    <Box pad="small" round={true} style={{ position: 'fixed', bottom: '10%', right: '5%', zIndex: '10' }}>
      {!showChat && (
        <Box
          background="brand"
          round={true}
          height="xxsmall"
          width="small"
          align="center"
          justify="center"
          elevation="medium"
          direction="row"
          gap="small"
          onClick={() => setShowChat(true)}
        >
          AI Chat
          <Chat size="medium" enableBackground="true" />
        </Box>
      )}
      <Collapsible direction="vertical" open={showChat}>
        <Card
          height="large"
          width="large"
          pad="small"
          background="light-1"
          round="small"
          border="all"
          elevation="medium"
          overflow={{ vertical: 'auto' }}
        >
          <Box direction="column" gap="small" align="end" height="100%">
            <Close onClick={() => setShowChat(false)} cursor="pointer" />
            <MessageList messages={messageState.messages} messageListRef={messageListRef} />
            <UserInput
              handleSubmit={handleSubmit}
              loading={loading}
              handleEnter={handleEnter}
              textAreaRef={textAreaRef}
              query={query}
              setQuery={setQuery}
            />
          </Box>
        </Card>
      </Collapsible>
    </Box>
  );
});
