/**
 * Grommet Theme
 *
 * https://v2.grommet.io/grommet
 */

const grommetTheme = {
  global: {
    colors: {
      brand: '#25094b',
      'accent-1': '#ffffff',
      'accent-2': '#ed9ac2',
      'accent-3': '#a559a3',
      'accent-4': '#4c489e',
      alert: 'red',
    },
    focus: {
      outline: {
        size: '0px',
      },
    },
  },
  accordion: {
    border: undefined,
  },
  tab: {
    active: {
      color: 'accent-3',
    },
    hover: {
      background: 'accent-2',
    },
    margin: 'small',
    pad: 'small',
  },
  tabs: {
    gap: 'medium',
  },
  textArea: {
    extend: () => `
    resize: none;
    font-size: 1.1rem;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid #d9d9e3;
    background: #ffffff;
    color: #000;
    outline: none;  
    `,
  },
};

export default grommetTheme;
