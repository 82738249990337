import { Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEstimateStore } from '/src/context';

export interface ISessionEstimateFooterProps {
  addEstimateItem: () => void;
}

export const SessionEstimateFooter = observer(({ addEstimateItem }: ISessionEstimateFooterProps) => {
  const estimateStore = useEstimateStore();

  const AddItemLabel = (
    <Text size="small" weight="bold">
      ADD ITEM
    </Text>
  );

  return (
    <Box
      gap="small"
      pad={{ vertical: 'small', horizontal: 'medium' }}
      background="white"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      }}
    >
      {estimateStore.canEditCurrent && (
        <Box width="15%" pad={{ vertical: 'xsmall' }}>
          <Button
            secondary
            label={AddItemLabel}
            icon={<Add size="16px" />}
            tip="Add estimate item"
            onClick={addEstimateItem}
          />
        </Box>
      )}
    </Box>
  );
});
