import { Box, BoxProps, MaskedInput } from 'grommet';

export interface ITimeInputProps {
  time: string;
  setTime: (value: string) => void;
  boxProps?: BoxProps;
}

export const TimeInput = ({ time, setTime, boxProps }: ITimeInputProps) => {
  return (
    <Box {...boxProps}>
      <MaskedInput
        mask={[
          {
            length: [1, 2],
            options: Array.from({ length: 12 }, (v, k) => k + 1),
            regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
            placeholder: 'hh',
          },
          { fixed: ':' },
          {
            length: 2,
            options: ['00', '15', '30', '45'],
            regexp: /^[0-5][0-9]$|^[0-9]$/,
            placeholder: 'mm',
          },
          { fixed: ' ' },
          {
            length: 2,
            options: ['am', 'pm'],
            regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
            placeholder: 'ap',
          },
        ]}
        value={time}
        onChange={(event) => setTime(event.target.value)}
      />
    </Box>
  );
};
