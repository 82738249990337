import { Box, Button, Layer, Select, Text, TextInput } from 'grommet';
import { Video } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Shoot, TShootId } from '/lib/api';
import { useEstimateStore, useProjectStore } from '/src/context';

export interface ICreateEstimateModalProps {
  shoots: Shoot[];
  setShowModal: (isVisible: boolean) => void;
}

export const CreateEstimateModal = observer(({ shoots, setShowModal }: ICreateEstimateModalProps) => {
  const projectStore = useProjectStore();
  const estimateStore = useEstimateStore();

  const defaultTitle = useRef('New Estimate');

  const [title, setTitle] = useState(defaultTitle.current);
  const [shootId, setShootId] = useState<TShootId>();

  const createEstimate = async (title: string, shootId?: TShootId) => {
    if (!projectStore.currentProject?.id) {
      toast.warn('Select a project to create a new Estimate.');
      return;
    }

    const newEstimate = await estimateStore.createEstimate(projectStore.currentProject.id, title, shootId);
    const estimate = await estimateStore.getEstimate(newEstimate?.id, 'session_estimates,shoot');

    if (estimate) {
      estimateStore.setCurrentEstimate(estimate);
      setTitle('');
      setShootId(undefined);
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (shootId && title === defaultTitle.current) {
      const shoot = shoots.find((s) => s.id === shootId);
      if (shoot) setTitle(`${shoot.name} Estimate`);
    }
  }, [shoots, shootId, title]);

  return (
    <Layer
      onEsc={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
      position="center"
      modal={true}
      margin="large"
    >
      <Box
        direction="column"
        align="start"
        pad="small"
        justify="around"
        width="medium"
        height="medium"
        background="light-1"
        round="small"
      >
        <Box direction="row" pad="small" gap="small" align="center" fill="horizontal">
          <Box width="20%">
            <Text>Title:</Text>
          </Box>
          <Box fill="horizontal">
            <TextInput value={title} onChange={(e) => setTitle(e.target.value)} />
          </Box>
          {/* <EditableText val={title} onChange={setTitle} textSize="xlarge" /> */}
        </Box>
        <Box direction="row" gap="small" pad="small" align="center" fill="horizontal">
          <Box width="20%">
            <Text>Shoot:</Text>
          </Box>
          <Box fill="horizontal">
            <Select
              id="select-shoot"
              name="shoot"
              placeholder="Shoot"
              options={shoots}
              labelKey="name"
              valueKey="id"
              onChange={({ value }) => setShootId(value.id)}
              icon={<Video />}
            />
          </Box>
        </Box>
        <Button
          pad="small"
          margin="small"
          hoverIndicator
          onClick={() => createEstimate(title, shootId)}
          label="Create Estimate"
        />
      </Box>
    </Layer>
  );
});
