import { Box, Button, Heading, Text, Tip } from 'grommet';
import { Table } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AddNewButton, CreateEstimateModal, Delete } from '..';
import { Shoot } from '/lib/api';
import { useEstimateStore, useProjectStore, useShootStore } from '/src/context';

export interface IEstimateListProps {
  gridArea?: string;
}

export const EstimateList = observer(({ gridArea }: IEstimateListProps) => {
  const projectStore = useProjectStore();
  const estimateStore = useEstimateStore();
  const shootStore = useShootStore();
  const [showModal, setShowModal] = useState(false);
  const [shoots, setShoots] = useState<Shoot[]>([]);

  useEffect(() => {
    if (!projectStore.currentProject?.id) return;
    if (estimateStore.estimateList?.length && !estimateStore.currentEstimate) {
      estimateStore.setCurrentEstimate(estimateStore.estimateList[0]);
    }
  }, [estimateStore.estimateList, projectStore.currentProject]);

  useEffect(() => {
    const getShoots = async () => {
      if (!projectStore.currentProject?.id) return;
      const sh = await shootStore.listShoots(projectStore.currentProject.id);
      setShoots(sh || []);
    };
    getShoots();
  }, [projectStore.currentProject, showModal]);

  const deleteEstimate = async (id: string) => {
    await estimateStore.deleteEstimate(id);
  };

  return (
    <Box
      direction="column"
      pad="medium"
      overflow={{ vertical: 'auto' }}
      align="start"
      id="Estimate-list"
      gridArea={gridArea}
    >
      <Box direction="row">
        <Heading level="3" margin="medium">
          Estimates
        </Heading>
        <AddNewButton onClick={() => setShowModal(true)} tipText="New Estimate" icon={<Table />} />
      </Box>
      {estimateStore.estimateList?.map((e) => (
        <Box direction="row" key={e.id}>
          <Tip
            content={
              <Box width={{ max: 'medium' }} background="light-2" round="small" pad="small" margin="small" border="all">
                <Text>{e.title}</Text>
              </Box>
            }
            plain={true}
          >
            <Button
              pad="small"
              margin="small"
              hoverIndicator
              onClick={() => estimateStore.setCurrentEstimate(e)}
              label={e.title.length > 35 ? `${e.title.slice(0, 34)}...` : e.title}
              active={e.id === estimateStore.currentEstimate?.id}
            />
          </Tip>
          <Delete onClick={() => deleteEstimate(e.id)} name={e.title} />
        </Box>
      ))}
      {!estimateStore.estimateList?.length && <Text>No previous Estimates found for this project.</Text>}
      {showModal && <CreateEstimateModal shoots={shoots} setShowModal={setShowModal} />}
    </Box>
  );
});
