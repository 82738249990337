import dayjs, { Dayjs } from 'dayjs';
import { AmPm, DateString, DateTimeObject, Hours, ISODateString, Minutes, TimeString } from '../types';
import { SessionActivity } from '/lib/api';

export const hourOptions: Hours = Array.from({ length: 12 }, (_, i) => (!(i % 12) ? 12 : i % 12).toString());
export const minuteOptions: Minutes = Array.from({ length: 4 }, (_, i) => (!i ? '00' : (i * 15).toString()));
export const ampmOptions: AmPm = ['AM', 'PM'];

export const getTimeOptions = () => {
  return Array.from({ length: hourOptions.length * minuteOptions.length * ampmOptions.length }, (_, i) => {
    const hi = Math.floor(i / minuteOptions.length);
    const h = hourOptions[hi % 12];
    const m = minuteOptions[i % minuteOptions.length];
    const a = ampmOptions[Math.floor(hi / 12)];
    return `${h}:${m} ${a}`;
  });
};

export const getDateString = (input: ISODateString): DateString => dayjs(input).format('YYYY-MM-DD');
export const getTimeString = (input: ISODateString): TimeString => dayjs(input).format('h:mm A');

export const parseIsoDateString = (input: ISODateString): DateTimeObject => ({
  date: getDateString(input),
  time: getTimeString(input),
});

export const isSameOrAfter = (time: string | Dayjs, comparisonTime: string | Dayjs): boolean => {
  const t = typeof time === 'string' ? dayjs(time) : time;
  const t2 = typeof comparisonTime === 'string' ? dayjs(comparisonTime) : comparisonTime;
  return t.isSame(t2) || t.isAfter(t2);
};

export const isSameOrBefore = (time: string | Dayjs, comparisonTime: string | Dayjs): boolean => {
  const t = typeof time === 'string' ? dayjs(time) : time;
  const t2 = typeof comparisonTime === 'string' ? dayjs(comparisonTime) : comparisonTime;
  return t.isSame(t2) || t.isBefore(t2);
};

export const timeIsBetween = (
  time: string | Dayjs,
  start: string | Dayjs,
  end: string | Dayjs,
  checkIsSame = true
): boolean => {
  const t = typeof time === 'string' ? dayjs(time) : time;
  const s = typeof start === 'string' ? dayjs(start) : start;
  const e = typeof end === 'string' ? dayjs(end) : end;

  let isBetween = false;

  if ((checkIsSame ? isSameOrAfter(t, s) : t.isAfter(s)) && (checkIsSame ? isSameOrBefore(t, e) : t.isBefore(e)))
    isBetween = true;

  return isBetween;
};

export const getOverlapping = (sessionActivities: SessionActivity[]): SessionActivity[] => {
  const overlapping: SessionActivity[] = [];
  const activities = sessionActivities;

  while (activities.length) {
    const activity = activities.shift();
    if (!activity) break;

    for (const otherActivity of activities) {
      const startOverlaps = timeIsBetween(activity.start_time, otherActivity.start_time, otherActivity.end_time, false);
      const endOverlaps = timeIsBetween(activity.end_time, otherActivity.start_time, otherActivity.end_time, false);
      if (startOverlaps || endOverlaps) {
        overlapping.push(otherActivity);
      }
    }
  }

  console.log('[getOverlapping] Got overlapping Activities: %O', overlapping);

  return overlapping;
};

export const hasOverlapping = (sessionActivities: SessionActivity[]): boolean =>
  !!getOverlapping(sessionActivities).length;

export const getIsOverlapping = (dateTime: ISODateString, sessionActivities: SessionActivity[]): boolean => {
  let isOverlapping = false;
  for (const otherActivity of sessionActivities) {
    if (timeIsBetween(dateTime, otherActivity.start_time, otherActivity.end_time, false)) {
      isOverlapping = true;
    }
  }
  return isOverlapping;
};
